<template>
  <div>
    <div v-if="categoryShow" class="category">
      <div class="category_left">
        <el-button v-for="(item,index) in category" :key="index" size="mini" :class="item.select?'select':'none'" @click="categorySelect(item)">
          {{ item.name }}
        </el-button>
      </div>
      <div class="search_inp">
        <input v-model="form.content" type="text" placeholder="请输入内容" class="inputs" @keyup.enter="searchClick()">
        <p class="hot_words"></p>
        <span class="search_btn" @click="searchClick()">搜索</span>
      </div>
    </div>
    <div v-if="zlyc.length>0" class="big-list">
      <h3>
        <span>{{ title }}</span>
        <el-button
          v-if="zlyc.length>0"
          type="text"
          style="float:right;margin-top:10px;"
          @click="sendMsg"
        >更多></el-button>
      </h3>
      <div v-if="!noresult" class="small_box">
        <div
          v-for="(item, index) in zlyc"
          :key="index"
          class="small_list"
          @click="giftinfo(item)"
        >
          <div v-show="!item.listType">
            <img :src="item.img" alt="">
            <el-tooltip class="item" effect="dark" :content="item.gift_name" placement="top">
              <h4>{{ item.gift_name }}</h4>
            </el-tooltip>
            <div class="small_count">
              <img src="@/assets/image/hb.png" alt="">
              <span>{{ item.quantity }}</span>
              <!-- <span style="display: inline-block;padding-left: 2px">起</span> -->
              <em>库存{{ item.num }}</em>
            </div>
          </div>
          <div v-show="item.listType&&$route.query.id" class="moreDiv">
            <img class="lwimg" src="../../assets/lp.png" alt="">
            <p class="mp">更多精美礼品</p>
            <p class="qp">敬请期待</p>
          </div>
        </div>
        <div v-show="zlyc.length<=0" class="moreDiv">
          <img class="lwimg" src="../../assets/lp.png" alt="">
          <p class="mp">更多精美礼品</p>
          <p class="qp">敬请期待</p>
        </div>
      </div>
    </div>
    <div v-if="noresult" class="noresult">
      <img src="@/assets/image/no_result.png" alt="">
      <p>
        抱歉，没有找到与“<em>{{ msg }}</em>”相关的商品
      </p>
    </div>
    <div v-if="noresult" class="black_line"></div>
  </div>
</template>
<script>
import Cookies from 'js-cookie'
export default {
  name: 'Indexsquare',
  props: {
    zlyc: {
      type: Array,
      default: function() {
        return []
      }
    },
    title: {
      type: String,
      default: ''
    },
    category: {
      type: Array,
      default: function() {
        return []
      }
    },
    categoryShow: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: ''
    },
    showflag: {
      type: Boolean,
      default: true
    },
    noresult: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      msg: '',
      form: {
        content: '',
        zb: ''
      }
    }
  },
  watch: {
    $route() {
      this.msg = this.$route.query.content
    }
  },
  mounted() {
    this.msg = this.$route.query.content
    this.$emit('is_div_show', this.$route.query.id)
  },
  methods: {
    categorySelect(item) {
      this.category.forEach(e => {
        e.select = false
      })
      item.select = true
      this.form.zb = item.id
      this.$emit('listWithCategory', item.id)
    },
    searchClick() {
      console.log(111111111)
      this.$router.push({
        path: '/gift/searchResult',
        query: { category_id: this.form.zb, content: this.form.content }
      })
    },
    sendMsg() {
      Cookies.set('index_type', this.type)
      this.$emit('sendMsg', this.type)
    },
    giftinfo(item) {
      this.$router.push({
        path: '/gift/giftExchange',
        query: { giftid: item.id }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.lwimg{
  width: 120px !important;
  height: 120px !important;
  margin-left: 32px;
  margin-top: 25px;
}
.moreDiv{
  .mp{
    font-size: 16px;
    text-align: center;
    padding: 30px 0 0 35px;
  }
  .qp{
    font-size: 16px;
    text-align: center;
    color: red;
    padding: 0 0 0 33px;
  }
  img{
    width: 150px;
    height: 150px;
  }
}
.big-list {
  // width: 1200px;
  width: 1140;
  margin: 0px auto;
  padding: 20px 0px;
  h3 {
    height: 60px;
    line-height: 60px;
    font-size: 28px;
    // color: #ee2d2e;
    color: #333;
    font-family: 'PingFangSC-Medium';
    padding-right: 10px;
    border-bottom: 1px solid #ccc;
    span {
      border-bottom: 2px solid #ee2d2e;
      float: left;
      height: 100%;
    }
  }
}
.small_box {
  padding: 45px 0 0;
  overflow: hidden;
  .small_list {
    background-color: #fff;
    width: 255px;
    height: 337px;
    margin-right: 40px;
    margin-bottom: 35px;
    padding: 0px 30px 20px 0px;
    float: left;
    cursor: pointer;
    img {
      width: 255px;
      height: 255px;
      margin-bottom: 8px;
    }
    h4 {
      font-size: 16px;
      color: #333;
      padding: 0 5px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      padding-left: 30px;
      font-weight: 600;
    }
  }
  .small_list:nth-child(4n) {
    margin-right: 0px;
  }
  .small_count {
    line-height: 35px;
    overflow: hidden;
    img{
      float: left;
      color: #ee2d2e;
      // margin-left: 40px;
      position: relative;
      left: 25px;
    }
    span {
      float: left;
      color: #ee2d2e;
      padding-left: 26px;
    }
    img {
      width: 20px;
      height: 15px;
      margin: 10px 0px;
      margin-right: 5px;
    }
    em {
      font-style: normal;
      float: right;
      color: #666;
      font-style: 12px;
    }
  }
}
.noresult {
  width: 1200px;
  margin: 0 auto;
  background: white;
  padding: 133px 0;
  text-align: center;
  img {
    width: 139px;
    height: 139px;
  }
  p {
    font-size: 14px;
    line-height: 35px;
    margin: 20px 0px;
    em {
      color: #ee2d2e;
      font-style: normal;
    }
  }
}
.black_line {
  height: 60px;
}

.category{
  padding: 20px 0;
  border-bottom: 1px solid #ccc;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.category_left{
  .select{
    width: auto;
    height: 30px;
    background: #ee2e2e;
    color: #fff;
    font-family: 'PingFangSC-Medium';
    border: none;
    font-size: 16px;
  }
  .none{
    background: none;
    color: #333;
    font-family: 'PingFangSC-Medium';
    border: none;
    font-size: 16px;
  }
}

.search_inp{
  width: 230px;
  position: relative;
  height: 36px;
}
.inputs{
  width: 100%;
  height: 27px;
  position: absolute;
  padding: 17px 10px;
  box-sizing: border-box;
  border-radius: 20px;
  line-height: 27px;
  left: 0;
  border:1px solid #ccc;
  outline: none;
}
.hot_words{
  min-width: 20px;
  height: 20px;
  position: absolute;
  top: 7px;
  right: 60px;
  display: flex;
  align-items: center;
}
.search_btn{
  width: 60px;
  display: inline-block;
  font-size: 12px;
  background: #ee2e2e;
  color: #fff;
  background-position: 45px 36px;
  height: 28px;
  line-height: 28px;
  text-align: center;
  border-radius: 14px;
  position: absolute;
  top: 4px;
  right: 5px;
  padding: 0 10px;
  cursor: pointer;
}
</style>
